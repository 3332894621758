import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Financing from "../components/Solutions/Financing/Financing";

import Favicon from "../images/Favicon.png";
import ogImage from "../images/Metadata/financing.jpg"

const FinancingPage = () => {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Financing - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Finance Drivosity's safety and productivity solution including GPS-equipped car toppers. PEAC Solutions offers competitive rates, quick approvals, and flexible terms. Apply now!"/>


                <meta property="og:title" content="Financing - Drivosity"/>
                <meta property="og:description" content="Finance Drivosity's safety and productivity solution including GPS-equipped car toppers. PEAC Solutions offers competitive rates, quick approvals, and flexible terms. Apply now!"/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Car financing concept with a calculator, car keys, and contract documents on a desk, representing auto loan and lease options."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/financing`}/>
            </Helmet>
            <Layout component={<Financing/>}/>
        </>
    )
}

export default FinancingPage
